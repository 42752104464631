import { render, staticRenderFns } from "./company-list-page.vue?vue&type=template&id=36b3a5bb&scoped=true&"
import script from "./company-list-page.vue?vue&type=script&lang=js&"
export * from "./company-list-page.vue?vue&type=script&lang=js&"
import style0 from "./company-list-page.vue?vue&type=style&index=0&id=36b3a5bb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b3a5bb",
  null
  
)

export default component.exports
import {QBreadcrumbs,QIcon,QBreadcrumbsEl} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBreadcrumbs,QIcon,QBreadcrumbsEl})
