import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class CompanyPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.companyRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.companyImport,
    );
    this.companyAutocomplete = permissionChecker.match(
      Permissions.values.companyAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.companyCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.companyEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.companyDestroy,
    );
  }
}
