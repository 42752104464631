//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import vueI18n from '@/vueI18n'
import { i18n } from '@/vueI18n';
import moment from 'moment';

import { CompanyPermissions } from '@/modules/company/company-permissions';
import { CompanyModel } from '@/modules/company/company-model';
const { fields } = CompanyModel;

export default {
  name: 'app-company-list-table',

  mounted() {
    this.doFilter();
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      filter: '',
      selection: [],
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'createdAt',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
      columns: [
        {
          name: 'name',
          field: 'name',
          label: i18n('entities.company.fields.name'), 
          align: 'center',
          format: (val) => `${val}`,
          // required: true,
          // sortable: true,
        },
        {
          name: 'email',
          field: 'email',
          label: i18n('entities.company.fields.email'),
          align: 'center',
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: i18n('entities.company.fields.phoneNumber'),
          align: 'center',
        },
        {
          name: 'socialLinks',
          field: 'socialLinks',
          label: i18n('entities.store.fields.socialLinks'),
          align: 'center',
        },
        {
          name: 'wallet',
          field: 'wallet',
          label: i18n('entities.guide.fields.wallet'),
          align: 'center',
        },
        {
          name: 'status',
          field: 'disabledAsStatus',
          label: i18n('entities.guide.fields.status'),
          align: 'center',
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: i18n('entities.company.fields.createdAt'),
          align: 'center',
        },
        {
          name: 'action',
          field: 'action',
          label:  i18n('common.action'),
          align: 'center',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      rows: 'company/list/rows',
      count: 'company/list/count',
      loading: 'company/list/loading',
      // toggleStatusloading: 'iam/view/statusLoading',
      // pagination: 'client/list/pagination',
      // paginationLayout: 'layout/paginationLayout',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    hasPermissionToEdit() {
      return new CompanyPermissions(this.currentUser).edit;
    },
    fields() {
      return fields;
    },
    language(){
      return vueI18n.locale
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    },
  },
  methods: {
    ...mapActions({
      doChangeSort: 'company/list/doChangeSort',
      doChangePaginationCurrentPage: 'company/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'company/list/doChangePaginationPageSize',
      doMountTable: 'company/list/doMountTable',
      doFetch: 'company/list/doFetch',
      // doToggleUserAccountStatus: 'iam/view/doToggleUserAccountStatus'
    }),
  //#region [ Helper Functions ]
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = CompanyModel.presenter(row, fieldName);
      return val ? val : 'ـــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = CompanyModel.presenter(row, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = CompanyModel.presenter(row, fieldName);
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(val) {
      // const val = GuideModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(CompanyModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("dddd");
    },
    presenterTime(row, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = CompanyModel.presenter(row, fieldName)
      let n = row[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterUser(row, fieldName, key) {
      const user = CompanyModel.presenter(row, fieldName);
      if (key == 'avatars' || key == 'avatar') {
        let avatar = null
        if (user && Array.isArray(user[key])) {
          avatar = user[key].length ? user[key][0].publicUrl : null
        } else if (user && user[key]) {
          avatar = user[key].publicUrl 
        }
        return avatar ? avatar : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c'
      }
      return user && user[key] ? user[key] : 'ـــ'; 
    },
    presenterClient(row, fieldName, key) {
      const val = CompanyModel.presenter(row, fieldName);
      return val && val[key] ? val[key] : 'ـــ'; 
    },
    presenterLocalization(data) {
      if (typeof data === 'object' && data !== null) {
        return data[this.currentLanguageCode] ? data[this.currentLanguageCode] : data['en']
        // if (this.currentLanguageCode in data) {
        //   return data[this.currentLanguageCode]
        // }
      }
      return  
    },
  //#endregion

    async doFilter() {
      const filter = {};
      return this.doFetch({
        filter,
      });
    },
  },
};
