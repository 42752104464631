//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

import CompanyListTable from '@/modules/company/components/company-list-table.vue';
// import CompanyListFilter from '@/modules/company/components/company-list-filter.vue';
// import CompanyListToolbar from '@/modules/company/components/company-list-toolbar.vue';

export default {
  name: 'app-company-list-page',

  components: {
    [CompanyListTable.name]: CompanyListTable,
    // [CompanyListFilter.name]: CompanyListFilter,
    // [CompanyListToolbar.name]: CompanyListToolbar,
  },
  computed:{
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isRTL() {
      // return localStorage.getItem('language') == 'ar'
      return this.currentLanguageCode == 'ar'
    },
  },
};
